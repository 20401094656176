import React, { KeyboardEvent, ReactNode, useState } from 'react';
import './App.css';
import DiamondComponent, { DiamondState } from './diamond/diamond';
import { useParams } from 'react-router-dom';
import { questions } from './data/questions';
import { moneys } from './data/moneys';
import { FaPhone } from "react-icons/fa";
import { FaPeopleLine } from "react-icons/fa6";
import { FaMusic } from "react-icons/fa";
import { FaFastForward } from "react-icons/fa";
import { FaStepForward } from "react-icons/fa";
import { FiTarget } from "react-icons/fi";
import { FaPause } from "react-icons/fa";

const player = new Audio();

function App() {
  let params = useParams();
  let [state, setState] = useState({ question: +(params.question || 0), phase: +(params.phase || 0), answered: null as number | null, hidden: [] as number[], used5050: false, usedCall: false, usedAudience: false })

  function renderAnswers(): ReactNode[] {
    const nodes: ReactNode[] = [];
    const question = questions[state.question];
    if (!question) {
      return nodes;
    }
    for (let i = 0; i < Math.min(question.answers.length, state.phase - 1); i++) {
      nodes.push(
        <div className={`answer-diamond-wrapper animate-in`} onClick={_ => answer(i)}>
          <DiamondComponent text={`${String.fromCharCode("A".charCodeAt(0) + i)}: ${question.answers[i]}`} answerState={state.answered !== null ? (i === question.correct ? DiamondState.Right : state.answered === i ? DiamondState.Wrong : DiamondState.Normal) : DiamondState.Normal} hidden={state.hidden.includes(i)} />
        </div>
      )
    }

    return nodes;
  }

  function renderMoneys(): ReactNode[] {
    const nodes = [];
    for (let i = 0; i < moneys.length; i++) {
      nodes.push(
        <div className={`money-diamond-wrapper`}>
          <DiamondComponent text={`${moneys[i].toFixed(2)} €`} answerState={state.question === i ? DiamondState.Current : DiamondState.Normal} />
        </div>
      )
    }

    return nodes;
  }

  function handleKeys(e: KeyboardEvent<HTMLDivElement>) {
    if (e.key === 'ArrowRight') {
      nextPhase();
    } else if (e.key === 'ArrowLeft') {
      previousPhase();
    } else if (e.key === 'ArrowUp') {
      nextQuestion();
    } else if (e.key === 'ArrowDown') {
      previousQuestion();
    }
  }

  function nextPhase() {
    const question = questions[state.question];
    if (state.phase <= question.answers.length) {
      setState({ ...state, phase: state.phase + 1 });
    }
  }

  function previousPhase() {
    if (state.phase > 0) {
      setState({ ...state, phase: state.phase - 1 });
    }
  }

  function nextQuestion() {
    if (state.question < questions.length - 1) {
      setState({ ...state, question: state.question + 1, phase: 0, answered: null, hidden: [] });
    }
  }

  function previousQuestion() {
    if (state.question > 0) {
      setState({ ...state, question: state.question - 1, phase: 0, answered: null, hidden: [] });
    }
  }

  function answer(i: number) {
    setState({...state, answered: i});
    const question = questions[state.question];
    if (i === question.correct) {
      playSound("right.mp3");
    } else {
      playSound("wrong.mp3");
    }
  }

  function consume5050() {
    function shuffle(array: Array<number>) {
      let currentIndex = array.length,  randomIndex;
      while (currentIndex > 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }
    
      return array;
    }

    if (!state.used5050) {
      const indices = Array.from(questions[state.question].answers.keys());
      shuffle(indices);
      setState({...state, used5050: true, hidden: [indices[0], indices[1]]});
    }
  }

  function consumeCall() {
    setState({...state, usedCall: true});
  }

  function consumeAudience() {
    setState({...state, usedAudience: true});
  }

  

  function playSound(file: string) {
    function playListener() {
      player.play();
      player.removeEventListener('canplay', playListener);
    }

    player.setAttribute('src', file);
    player.addEventListener('canplay', playListener);
  }

  function stopSound() {
    player.pause();
  }

  return (
    <div className='app' onKeyDown={handleKeys} tabIndex={0}>
      <div className='sound-area'>
        <FaMusic onClick={() => playSound("tunnari.mp3")} />
        <br></br>
        <FaFastForward onClick={() => playSound("fast.mp3")} />
        <br></br>
        <FaStepForward onClick={() => playSound("suspense.mp3")} />
        <br></br>
        <FiTarget onClick={() => playSound("answered.mp3")} />
        <br></br>
        <FaPause onClick={() => stopSound()}/>
      </div>
      <div className='question-area'>
        <div className='logo'>
          <img src='logo.png' alt='no' />
        </div>
        <div className='question'>
          {state.phase ? <DiamondComponent text={questions[state.question].question} key={state.question} /> : <></>}
        </div>
        <div className='answers'>
          {renderAnswers()}
        </div>
      </div>
      <div className='money-area'>
        <div className='money-tree'>
          {renderMoneys()}
        </div>
        <div className='lifelines'>
          <div className={`lifeline fiftyfifty ${state.used5050 ? 'disabled': ''}`} onClick={_ => consume5050()}>
            50/50
          </div>
          <div className={`lifeline call ${state.usedCall ? 'disabled': ''}`} onClick={_ => consumeCall()}>
            <FaPhone />
          </div>
          <div className={`lifeline audience ${state.usedAudience ? 'disabled': ''}`} onClick={_ => consumeAudience()}>
            <FaPeopleLine />
          </div>
        </div>
      </div>
    </div>

  );
}

export default App;
