import './diamond.css';

export enum DiamondState {
  Normal = 0,
  Right = 1,
  Wrong = 2,
  Current = 3
};

const DiamondComponent = (props: {text: string, answerState?: DiamondState, hidden?: boolean}) => {
  const path = "M 0 20 C 10 20 10 0 20 0 L 100 0 C 110 0 110 20 120 20 C 110 20 110 40 100 40 L 20 40 C 10 40 10 20 0 20";

  return (
    <div className='diamond'>
      <svg viewBox='0 -2 120 44' preserveAspectRatio='none'>
        <path d={path} className={(props.answerState || DiamondState.Normal) === DiamondState.Normal ? '' : props.answerState === DiamondState.Right ? 'right' : props.answerState === DiamondState.Wrong ? 'wrong' : 'current'} />
      </svg>
      <div className={`diamond-text ${props.hidden ? 'hidden' : ''}`}>
        {props.text}
      </div>
    </div>
  );
};

export default DiamondComponent;