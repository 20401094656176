import { questions } from './questions';

const firstMoney = 0.05;
const lastMoney = 2;

const moneyMultStep = Math.pow(lastMoney / firstMoney, 1 / (questions.length - 1));

export const moneys: number[] = [];

for (let i = 0; i < questions.length; i++) {
    moneys.push(firstMoney * Math.pow(moneyMultStep, i));
}


