export const questions = [
    {
        question: "Mitä seuraavista käytetään tavallisesti paperien säilytykseen?", correct: 3, answers: [
            "Kölio", "Keulao", "Peräsino", "Kansio"
        ]
    },
    {
        question: "Mikä seuraavista on mahdollisesti vaarallinen luonnonilmiö?", correct: 3, answers: [
            "Meni", "Saapui", "Lähti", "Tuli"
        ]
    },
    {
        question: "Mitä kuullaan suomalaisten menestyttyä urheilussa?", correct: 3, answers: [
            "Tuleni", "Vetensä", "Ilmasi", "Maamme"
        ]
    },
    {
        question: "Miksi kutsutaan erityisesti naisten hihatonta ylävartalon vaatekappaletta?", correct: 3, answers: [
            "Mappi", "Tippi", "Keppi", "Toppi"
        ]
    },
    {
        question: "Mikä on yleisin borrelioosin aiheuttaja?", correct: 3, answers: [
            "Rokki", "Räppi", "Poppi", "Punkki"
        ]
    },
    {
        question: "Miksi kutsutaan fennoskandian pohjoisinta osaa?", correct: 3, answers: [
            "Leno", "Lisä", "Lanoppi", "Lappi"
        ]
    },
    {
        question: "Mitä soitinta voi soittaa polkemalla yhteen lautasia?", correct: 3, answers: [
            "Kau-luk-set", "Na-pit", "Lah-keet", "Hi-hat"
        ]
    },
    {
        question: "Mitä syödään usein borssikeiton, blinien tai lohen kanssa?", correct: 3, answers: [
            "Smampiainen", "Smiilimato", "Smyöperhonen", "Smetana"
        ]
    },
    {
        question: "Mikä korjaa kaiken?", correct: 3, answers: [
            "Sähkövedyte", "Öljytypete", "Kaukoheliumite", "Puuhapete"
        ]
    },
    
    
];